/**
 * Created by dpardell on 23/11/2015.
 */

angular
.module('annexaApp')
    .controller('SectionController', ['$scope', '$state', '$filter', 'HeaderService','$rootScope', 'CommonService', 'AdminFactory', 'Language', 'AnnexaFormlyFactory', 'CommonAdminModals', 'AnnexaModalFactory', 'dialogs', 'HelperService', 'DialogsFactory', '$q',
                function ($scope, $state, $filter, HeaderService, $rootScope, CommonService, AdminFactory, Language, AnnexaFormlyFactory, CommonAdminModals, AnnexaModalFactory, dialogs, HelperService, DialogsFactory, $q) {
        $rootScope.loading(false);
        $scope.sections = [];
        $scope.langColumn = Language.getActiveColumn();

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.sections') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton ml-sm-pt mb-sm-pt mb-md-0',undefined,'#admin-sections','fa fa-plus','global.commonAdmin.modal.section.titleNew',undefined,'newSection'),
                    new HeadButton('btn primary breadDivButton ml-sm-pt mb-sm-pt mb-md-0',undefined,'#admin-sections','fa fa-download','global.literals.export',undefined,'exportSections')
                ];
            }
        });

        HeaderService.changeState($state.current);

        $scope.sections = CommonService.getNestableData(angular.copy(AdminFactory.sections), 'section', 'orderView');

        $scope.toggle = function (scope) {
            scope.toggle();
        };

        $scope.newSection = function (node) {
            var modal = CommonAdminModals.sectionNew;
            modal.annexaFormly.model = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.labelProp = $scope.langColumn;
            modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.options = AdminFactory.sectionTypes;
            modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.labelProp = $scope.langColumn;
            modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.options = AdminFactory.councillors;
            modal.annexaFormly.fields[1].fieldGroup[0].templateOptions.options = AdminFactory.users;
            modal.annexaFormly.fields[1].fieldGroup[1].templateOptions.labelProp = $scope.langColumn;
            modal.annexaFormly.fields[1].fieldGroup[1].templateOptions.options = AdminFactory.sections;
            modal.annexaFormly.fields[1].fieldGroup[1].defaultValue = node == undefined ? null : node;
            AnnexaFormlyFactory.showModal('modalSection', modal, $scope.saveSection, false);
        };

        $scope.viewSection = function (section) {
            var modal = CommonAdminModals.sectionEdit;
            modal.data = angular.copy(section);
            if(modal.data.responsible) {
                if(!modal.data.responsible.completeName) {
                    modal.data.responsible.completeName = ((modal.data.responsible.name || '') + ' ' + (modal.data.responsible.surename1 || '') + ' ' + (modal.data.responsible.surename2 || '')).trim();
                }
            }
            modal.languageColumn = $scope.langColumn;
            modal.updateSection = $scope.updateSection;
            modal.sectionTypes = AdminFactory.sectionTypes;
            modal.councillors = AdminFactory.councillors;
            modal.responsibleColumn = 'completeName';
            modal.users = AdminFactory.users;
            modal.sections = AdminFactory.sections;

            AnnexaModalFactory.showModal('modalSectionEdit', modal);
        };

        $scope.deleteSection = function(section) {
            var dlg = dialogs.confirm(
                $filter('translate')('global.commonAdmin.literals.deleteSection'),
                $filter('translate')('global.commonAdmin.literals.confirmDeleteSection'),
                {
                    animation: true,
                    background: 'static',
                    keyboard: true,
                    size: ''
                }
            )

            dlg.result.then(function (btn) {
                AdminFactory.deleteSection(section)
                    .then(function (data) {
                        $scope.sections = CommonService.getNestableData(AdminFactory.sections, 'section', 'orderView');
                    }).catch(function (error) {
                });
            }, function (btn) {
            });
        };

        $scope.updateSection = function (val, prop) {
            var model = this.data;

            if(prop == 'language1' || prop == 'language2' || prop == 'language3') {
                model = val;
            } else {
                model[prop] = val;
            }

            $scope.saveSection(model);
        };

        $scope.saveSection = function (section, deferred) {
            var model = {};
            var isNew = false;
            var modal = undefined;

            if(section.hasOwnProperty('annexaFormly')) {
                modal = this;
                if (section.annexaFormly.model.row1) {
                    if (section.annexaFormly.model.row1.councillor) {
                        model.councillor = {id: section.annexaFormly.model.row1.councillor};
                    } else {
                        model.councillor = null;
                    }

                    if (section.annexaFormly.model.row1.type) {
                        model.type = {id: section.annexaFormly.model.row1.type};
                    } else {
                        model.type = null;
                    }
                } else {
                    model.councillor = null;
                    model.type = null;
                }

                if (section.annexaFormly.model.row2) {
                    if (section.annexaFormly.model.row2.parent) {
                        if(section.annexaFormly.model.row2.parent instanceof Object) {
                            model.parent = section.annexaFormly.model.row2.parent;
                        } else {
                            model.parent = {id: section.annexaFormly.model.row2.parent};
                        }
                    } else {
                        model.parent = null;
                    }

                    if (section.annexaFormly.model.row2.responsible) {
                        model.responsible = {id: section.annexaFormly.model.row2.responsible}
                    } else {
                        model.responsible = null;
                    }
                } else {
                    model.parent = null;
                    model.responsible = null;
                }

                if (section.annexaFormly.model.row3) {
                    if (section.annexaFormly.model.row3.language1) {
                        model.language1 = section.annexaFormly.model.row3.language1;
                    } else {
                        model.language1 = 'NotTranslated';
                    }

                    if (section.annexaFormly.model.row3.language2) {
                        model.language2 = section.annexaFormly.model.row3.language2;
                    } else {
                        model.language2 = 'NotTranslated';
                    }

                    if (section.annexaFormly.model.row3.language3) {
                        model.language3 = section.annexaFormly.model.row3.language3;
                    } else {
                        model.language3 = 'NotTranslated';
                    }
                    
                } else {
                    model.language1 = 'NotTranslated';
                    model.language2 = 'NotTranslated';
                    model.language3 = 'NotTranslated';                    
                }
                
                if (section.annexaFormly.model.row4) {
                	if (section.annexaFormly.model.row4.acronym) {
                        model.acronym = section.annexaFormly.model.row4.acronym;
                    } else {
                        model.acronym = null;
                    }
                	
                	if (section.annexaFormly.model.row4.code) {
                        model.code = section.annexaFormly.model.row4.code;
                    } else {
                        model.code = null;
                    }
                } else {
                	model.acronym = null;
                	model.code = null;
                }
                
                isNew = true;
            } else {
                model = section;
            }

            AdminFactory.newSection(model)
                .then(function(data) {
                    $scope.sections = CommonService.getNestableData(AdminFactory.sections, 'section','orderView');
                    if(isNew && modal) {
                        modal.close();
                    }
                    
                    if (deferred) {
                    	deferred.resolve(true);
                    }
                }).catch(function (error) {
                	if (deferred) {
                		deferred.resolve(false);
                	}
            });
        };

        $scope.treeOptions = {
            beforeDrop : function (e) {
            	var deferred = $q.defer();            	
            	DialogsFactory.confirm('global.commonAdmin.literals.moveSection', 'global.commonAdmin.literals.confirmMoveSection').then(function (dataAux) {
	                var sourceValue = e.source.nodeScope.$modelValue.section;
	                var sourceParentValue = null;
	                if( e.source.nodeScope.$modelValue.section.parent != null &&  e.source.nodeScope.$modelValue.section.parent != undefined) {
	                    sourceParentValue = e.source.nodeScope.$modelValue.section.parent.id;
	                }
	                var destValue = e.dest.nodesScope.node ? e.dest.nodesScope.node.section: null;
	                if(destValue != null && destValue != undefined) {
                    	sourceValue.parent = destValue;
	                }else {
	                    sourceValue.parent = null;
	                }
	                if(e.dest != null){
	                	sourceValue.orderView = e.dest.index+1;
	                }
	                $scope.saveSection(sourceValue, deferred);
	                
            	}).catch(function (data) {
                	deferred.reject(false);
	            });  
            	
            	return deferred.promise;	
            }
        };


        $scope.exportSections = function(){
            $rootScope.showLoadingdivSignin = true;
            try {
                var cells = ['acronym',$scope.langColumn, 'responsible'];
                var cellsAux = ['name','surename1','surename2'];
                var fileName = "sections.xls";
                var xlsFile = HelperService.exportToExcel($scope.sections, 'nodes', cells, 'section', fileName, window, 'global.commonAdmin.tabs.sections','userSections',cellsAux,'responsible',cellsAux, 'user');
                $rootScope.showLoadingdivSignin = false;
            } catch(e) {
                $rootScope.showLoadingdivSignin = false;
                console.log(e.message);
            }
        };

    }]);
