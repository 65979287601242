/**
 * Created by dpardell on 23/11/2015.
 */

angular
.module('annexaApp')
    .controller('ClassificationController', ['$scope', '$http', '$state', '$compile', '$filter', 'apiAdmin', 'CommonAdminService','$nestable', 'HeaderService','$rootScope', 'Language', 'AdminFactory', 'CommonService', 'CommonAdminModals', 'AnnexaFormlyFactory', 'dialogs', 'AnnexaModalFactory' , 'HelperService', 'DialogsFactory', '$q', function ($scope, $http, $state, $compile, $filter, apiAdmin, CommonAdminService, $nestable, HeaderService, $rootScope, Language, AdminFactory, CommonService, CommonAdminModals, AnnexaFormlyFactory, dialogs, AnnexaModalFactory, HelperService, DialogsFactory, $q) {
        $rootScope.loading(false);
        $scope.archiveClassifications = [];
        $scope.langColumn = Language.getActiveColumn();

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.classification') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn primary breadDivButton ml-sm-pt',undefined,'#admin-classifications','fa fa-plus','global.commonAdmin.newClassification',undefined,'newClassification'),
                    new HeadButton('btn primary breadDivButton ml-sm-pt',undefined,'#admin-classifications','fa fa-download','global.literals.export',undefined,'exportClassifications')
                ];
            }
        });

        HeaderService.changeState($state.current);

        $scope.archiveClassifications = CommonService.getNestableData(AdminFactory.archiveClassifications, 'archiveClassification', 'orderView');

        $scope.toggle = function (scope) {
            scope.toggle();
        };

        $scope.newClassification = function (node) {
            var modal = CommonAdminModals.classificationNew;
            modal.annexaFormly.model = {};
            modal.annexaFormly.options = {};
            if(node && !node.pageX) {
                modal.annexaFormly.fields[0].fieldGroup[4].defaultValue = node == undefined ? null : node;
            }
            AnnexaFormlyFactory.showModal("modalClassification", modal, $scope.saveClassification, false);
        }

        $scope.viewClassification = function (classification) {
            var modal = CommonAdminModals.classificationEdit;
            modal.data = angular.copy(classification);
            modal.languageColumn = Language.getActiveColumn();
            modal.updateClassification = $scope.updateClassification;

            AnnexaModalFactory.showModal('modaClassificationEdit', modal);
        }

        $scope.deleteClassification = function(classification) {
            var dlg = dialogs.confirm(
                $filter('translate')('global.commonAdmin.literals.deleteClassification'),
                $filter('translate')('global.commonAdmin.literals.confirmDeleteClassificationMessage'),
                {
                    animation: true,
                    backdrop: 'static',
                    keyboard: true,
                    size: ''
                }
            );

            dlg.result.then(function (btn) {
                AdminFactory.deleteClassification(classification)
                    .then(function (data) {
                        $scope.archiveClassifications = CommonService.getNestableData(AdminFactory.archiveClassifications, 'archiveClassification', 'orderView');
                }).catch(function (error) {
                    var err = dialogs.error(
                        $filter('translate')('DIALOGS_ERROR_MSG'),
                        $filter('translate')(error.data.message),
                        {
                            animation: true,
                            backdrop: 'static',
                            keyboard: true,
                            size: ''
                        }
                    );
                });
            }, function (btn) {
            });
        }

        $scope.updateClassification = function(val, prop) {
            var okUpdate = true;
            if(prop && prop != this.languageColumn){
                if(val) {
                    this.data[prop] = val;
                }else{
                    okUpdate = false;
                }
            }else if(prop == Language.getActiveColumn()){
                var cont = 1;
                var column = "language";
                if($rootScope.app.languagedef){
                    angular.forEach($rootScope.app.languagedef, function (value2, key2) {
                        if (val && !val[column + cont]) {
                            okUpdate = false;
                        }
                        cont++;
                    });
                }
            }
            if(okUpdate) {
                var result = $scope.saveClassification(this.data);
                if (result) {
                    return result;
                }
            }else{
                return $filter('translate')('global.validation.required');
            }
        }

        $scope.saveClassification = function (classification, deferred) {
            var classificationModel = classification;
            var isNew = false;
            var modal = undefined;

            if(classification.hasOwnProperty('annexaFormly')) {
                modal = this;
                classificationModel = classification.annexaFormly.model.row1;
                isNew = true;
            }

            AdminFactory.newClassification(classificationModel)
                .then(function(data) {
                    $scope.archiveClassifications = CommonService.getNestableData(AdminFactory.archiveClassifications, 'archiveClassification', 'orderView');
                    if(isNew && modal) {
                        modal.close();
                    }
                    
                    if (deferred) {
                    	deferred.resolve(true);
                    }
                }).catch(function (error) {
                    var errorMSG = 'DIALOGS_ERROR_MSG';
                    if(error.data && error.data.message){
                        errorMSG = error.data.message;
                    }
                    if (deferred) {
                		deferred.resolve(false);
                	}
                    if(isNew && modal) {
                        modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.alerts.push({msg: errorMSG});
                    }else{
                        return $filter('translate')(errorMSG);
                    }
            });
        }

        $scope.treeOptions = {
            beforeDrop : function (e) {
            	var deferred = $q.defer();
            	DialogsFactory.confirm('global.commonAdmin.literals.moveClassification', 'global.commonAdmin.literals.confirmMoveClassification').then(function (dataAux) {
	                var sourceValue = e.source.nodeScope.$modelValue.archiveClassification;
	                var sourceParentValue = null;
	                if( e.source.nodeScope.$modelValue.archiveClassification.parent != null &&  e.source.nodeScope.$modelValue.archiveClassification.parent != undefined) {
	                    sourceParentValue = e.source.nodeScope.$modelValue.archiveClassification.parent.id;
	                }
	                var destValue = e.dest.nodesScope.node ? e.dest.nodesScope.node.archiveClassification: null;
	                if(destValue != null && destValue != undefined) {
                    	sourceValue.parent = destValue;
	                }else {
	                    sourceValue.parent = null;
	                }
	                if(e.dest != null){
	                	sourceValue.orderView = e.dest.index+1;
	                }
	                $scope.saveClassification(sourceValue, deferred);
            	}).catch(function (data) {
                	deferred.reject(false);
	            });  
            	
            	return deferred.promise;    
            }
        };


        $scope.exportClassifications = function(){
            $rootScope.showLoadingdivSignin = true;
            try {
                var cells = ['code',$scope.langColumn];
                var fileName = "archiveClassification.xls";
                var xlsFile = HelperService.exportToExcel($scope.archiveClassifications, 'nodes', cells, 'archiveClassification', fileName, window, 'global.literals.classificationBox');
                $rootScope.showLoadingdivSignin = false;
            } catch(e) {
                $rootScope.showLoadingdivSignin = false;
                console.log(e.message);
            }
        }
    }]);
