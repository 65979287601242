/**
 * Created by osirvent on 07/06/2016.
 */
angular
    .module('annexaApp')
    .controller('TransactionTypeController',['$scope', '$rootScope', 'AnnexaFormlyFactory', 'CommonAdminModals', 'HeaderService', '$state', 'TableFilter', 'DTColumnBuilder', '$filter', '$compile', 'RestService', 'Language', 'CommonAdminService', 'AnnexaModalFactory', 'apiAdmin', 'AdminFactory', 'TramFactory','dialogs',
        function($scope, $rootScope, AnnexaFormlyFactory, CommonAdminModals, HeaderService, $state, TableFilter, DTColumnBuilder, $filter, $compile, RestService, Language, CommonAdminService, AnnexaModalFactory, apiAdmin, AdminFactory, TramFactory, dialogs) {
        $scope.showLoadingdiv = false;

        $scope.tfilter = new TableFilter();
        $scope.tfilter.addElement(Language.getActiveColumn(), 'text', 0, 'global.literals.name');


        $scope.dtDefTransactionTypes = new TableData('tram','TransactionType','./api/common/byfiler','rt<"row"<"col-md-6"<"row"<"col-md-6"l><"col-md-6"i>>><"col-md-6"p>>','orderView',[[1, 'asc']]);

        var transactionTypeNameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name', Language.getActiveColumn());
        var transactionTypeColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                                                   new ActionButton('global.literals.see','editTransactionType([data])','fa-eye'),
                                                   [new ActionButton('global.literals.remove','deleteTransactionType([data])')]);

        $scope.tableTransactionTypes = {
            dtColumns:[
                DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                DTColumnBuilder.newColumn('orderView').withOption('width', '5%').withTitle($filter('translate')('global.literals.order')),
                DTColumnBuilder.newColumn(transactionTypeNameColumn.getColumn()).withTitle(transactionTypeNameColumn.getTitle()),
                DTColumnBuilder.newColumn('id').withOption('width', '12%').withOption('className', 'text-center').withTitle(transactionTypeColumn.getTitle()).notSortable().renderWith(transactionTypeColumn.getRender)
            ],
            filterCall: $scope.tfilter.getFilterCall(),
            filterCallAux: { langColumn: Language.getActiveColumn()},
            reLoadInternalData: function (resetPaging) {
                this.filterCall = $scope.tfilter.getFilterCall();
                this.filterCallAux = { langColumn: Language.getActiveColumn()};
                this.table.reloadData(function callbackInstance(json){}, resetPaging);
            }
        }

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.transaction_type') {
                $rootScope.subHeadButtons = [
                    new HeadButtonSearch("#tableFilter"),
                    new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-transactionType','fa fa-plus','global.commonAdmin.modal.transactionType.titleNew',undefined,'openNewTransactionType')
                ];
            }
        })

        HeaderService.changeState($state.current);

        $scope.saveTransactionType = function() {
            var data = this.annexaFormly.model.modal_body;
            if(data.phase && !data.phase.id){
                data.phase = {id:data.phase};
            }
            if(data.tramitationType && !data.tramitationType.id){
                data.tramitationType = {id:data.tramitationType};
            }
            var modal = this;
            CommonAdminService.insertOrUpdateTransactionType(data).then(function(data) {
                modal.close();
                $scope.doSearchFilter();
            }).catch(function(error) {
                if(data.phase && data.phase.id){
                    data.phase = data.phase.id;
                }
                if(data.tramitationType && data.tramitationType.id){
                    data.tramitationType = data.tramitationType.id;
                }
                console.log("ERROR");
            });

        };
        $scope.saveTransactionTypeEdit = function(modify, property) {
            var modal = this;
            var valid = true;

            if(property == 'language1') {
                var languages = $rootScope.app.languagedef;
                var cont = 1;
                var column = "language";
                if (languages) {
                    angular.forEach(languages, function (value2, key2) {
                        if (modal.data && !modal.data[column + cont]) {
                            valid = false;
                        }
                        cont++;
                    });
                }
            } else {
                modal.data[property] = modify;
            }

            if(valid) {
                CommonAdminService.insertOrUpdateTransactionType(modal.data)
                    .then(function (data) {
                        modal.transactionTypeAux = {
                            language1: modal.data.language1,
                            language2: modal.data.language2,
                            language3: modal.data.language3,
                            tramitationType: modal.data.tramitationType,
                            finallyType: modal.data.finallyType,
                            startType: modal.data.startType,
                            countDays: modal.data.countDays,
                            phase: modal.data.phase,
                            days: modal.data.days,
                            transactionSubtype: modal.data.transactionSubtype
                        };
                    $scope.doSearchFilter();
                }).catch(function (error) {
                    console.log("ERROR");
                });
            }else{
                return $filter('translate')('global.validation.required');
            }

        };
        $scope.cancelTransactionTypeEdit = function() {
            var modal = this;
            if(modal && modal.transactionTypeAux){
                modal.data.language1 = modal.transactionTypeAux.language1;
                modal.data.language2 = modal.transactionTypeAux.language2;
                modal.data.language3 = modal.transactionTypeAux.language3;
                modal.data.tramitationType = modal.transactionTypeAux.tramitationType;
                modal.data.finallyType = modal.transactionTypeAux.finallyType;
                modal.data.startType = modal.transactionTypeAux.startType;
                modal.data.countDays = modal.transactionTypeAux.countDays;
                modal.data.phase = modal.transactionTypeAux.phase;
                modal.data.days = modal.transactionTypeAux.days;
                modal.data.transactionSubtype = modal.transactionTypeAux.transactionSubtype;
            }
        };

        $scope.deleteTransactionType = function(id) {
            if(id) {
                var dlg = dialogs.confirm(
                    $filter('translate')('global.literals.deleteTransactionType'),
                    $filter('translate')('global.literals.confirmDeleteTransactionTypeMessage'),
                    {
                        animation: true,
                        backdrop: 'static',
                        keyboard: true,
                        size: ''
                    }
                );

                dlg.result.then(function (btn) {
                    CommonAdminService.deleteTransactionType(id).then(function (data) {
                        $scope.doSearchFilter();
                    }).catch(function (error) {
                        var err = dialogs.error(
                            $filter('translate')('DIALOGS_ERROR_MSG'),
                            $filter('translate')(error.data.message),
                            {
                                animation: true,
                                backdrop: 'static',
                                keyboard: true,
                                size: ''
                            }
                        );
                    });
                }, function (btn) {
                });


            }
        };

        $scope.editTransactionType = function(id) {
            RestService.findOne('TransactionType', id)
                .then(function(data) {
                    var modal = angular.copy(CommonAdminModals.transactionTypeEdit);
                    modal.yesNoList = apiAdmin.yesNoBoolean;
                    modal.transactionSubtypesList = apiAdmin.transactionSubtypes;
                    modal.data = JSOG.decode(data.data);
                    modal.transactionTypeAux = {language1:modal.data.language1, language2:modal.data.language2, language3:modal.data.language3,  finallyType:modal.data.finallyType, startType:modal.data.startType, countDays:modal.data.countDays, phase:modal.data.phase, days:modal.data.days, tramitationType: modal.data.tramitationType, transactionSubtype:modal.data.transactionSubtype};
                    modal.activeColumn = Language.getActiveColumn();
                    modal.saveTransactionTypeEdit = $scope.saveTransactionTypeEdit;
                    modal.cancelTransactioonTypeEdit = $scope.cancelTransactionTypeEdit;
                    modal.tramitationPhases = AdminFactory.tramitationPhases;
                    modal.tramitationTypes = TramFactory.dossierTramitationTypes;
                    modal.showTransactionSubtype = function(){
                    	var subtypeSelected = modal.transactionTypeAux.transactionSubtype;
                    	
                    	switch(subtypeSelected) {
                    		case 0: return $filter('translate')('global.literals.standard');
                    		case 1: return $filter('translate')('global.literals.proposalAgreement');
                    		case 2: return $filter('translate')('global.literals.manageAgreement');
                    		case 3: return $filter('translate')('global.literals.transferAgreement');
                    		case 4: return $filter('translate')('global.literals.amendmentAgreement');
                    		default: return 'empty';
                    	}
                    }
                    AnnexaModalFactory.showModal('modalTransactionTypeEdit', modal);
                });
        }

       $scope.openNewTransactionType = function () {
           var modal = angular.copy(CommonAdminModals.transactionType);
           modal.annexaFormly.model = {};
           modal.annexaFormly.model.modal_body = {};
           modal.annexaFormly.options = {};
           modal.annexaFormly.options.formState = { readOnly: false};
           modal.annexaFormly.fields[0].fieldGroup[2].templateOptions.labelProp = Language.getActiveColumn();
           modal.annexaFormly.fields[0].fieldGroup[2].templateOptions.options = TramFactory.dossierTramitationTypes;
           modal.annexaFormly.fields[0].fieldGroup[3].templateOptions.labelProp = Language.getActiveColumn();
           modal.annexaFormly.fields[0].fieldGroup[3].templateOptions.options = AdminFactory.tramitationPhases;
           AnnexaFormlyFactory.showModal("modalTransactionType", modal, $scope.saveTransactionType, false);
       }

        $scope.doSearchFilter=function(queued) {
            $scope.tableTransactionTypes.reLoadInternalData(true);
        }
        
    }]);