/**
 * Created by dpardell on 23/11/2015.
 */

angular
    .module('annexaApp')
    .controller('TaskTemplateController', ['$scope', '$http', '$state', '$compile', '$filter', 'DTColumnBuilder', 'apiAdmin', 'AnnexaFormlyFactory', 'CommonAdminModals', '$rootScope', 'HeaderService', 'AdminFactory', 'RestService', 'AnnexaModalFactory', 'TableFilter','Language', 'HelperService', 'ErrorFactory', 'dialogs',
        function ($scope, $http, $state, $compile, $filter, DTColumnBuilder, apiAdmin, AnnexaFormlyFactory, CommonAdminModals, $rootScope, HeaderService, AdminFactory, RestService, AnnexaModalFactory, TableFilter, Language, HelperService, ErrorFactory, dialogs) {

            $scope.tfilter = new TableFilter();
            $scope.tfilter.addElement(Language.getActiveColumn(), 'text', 0, 'global.literals.name');
            $scope.languageColumn = Language.getActiveColumn();
            $scope.descriptionLanguageColumn = "descriptionLanguage" + $scope.languageColumn.substr($scope.languageColumn.length - 1);

            $scope.dtDefTaskTemplate = new TableData('common', 'TaskTemplate', './api/common/byfiler').setSort([[2, 'asc']]).setSortName('days');

            var TaskTemplateNameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name',  $scope.descriptionLanguageColumn);
            var TaskTemplateActionColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                new ActionButton('global.literals.see','viewTaskTemplate([data])','fa-eye'),[
                new ActionButton('global.commonAdmin.datatable.options.delete','deleteTaskTemplate([data])')
                ]);
            $scope.getFilterCall = function(){
                var filterCall = $scope.tfilter.getFilterCall();
                if(filterCall.entryType && filterCall.entryType.id){
                    filterCall.entryType = filterCall.entryType.id;
                }
                return filterCall;
            };

            $scope.getFilterCallAux = function(){
                var filterCallAux = $scope.tfilter.getFilterCallAux();
                if(filterCallAux.procedure && filterCallAux.procedure.id){
                    filterCallAux.procedure = filterCallAux.procedure.id;
                }
                filterCallAux.langColumn = Language.getActiveColumn();
                return filterCallAux;
            };


            $scope.tableTaskTemplate = {
                dtColumns:[
                    DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                    DTColumnBuilder.newColumn(TaskTemplateNameColumn.getColumn()).withTitle(TaskTemplateNameColumn.getTitle()).notSortable(),
                    DTColumnBuilder.newColumn('days').withTitle($filter('translate')('global.literals.days')),
                    DTColumnBuilder.newColumn('id').withOption('width', '120px').withOption('className', 'text-center').withTitle(TaskTemplateActionColumn.getTitle()).notSortable().renderWith(TaskTemplateActionColumn.getRender)
                ],
                filterCall: $scope.getFilterCall(),
                filterCallAux : { langColumn: Language.getActiveColumn() },
                reloadInternalData: function (resetPaging) {
                    this.filterCall = $scope.getFilterCall();
                    this.filterCallAux =$scope.getFilterCallAux ();
                    this.table.reloadData(function callbackInstance(json){}, resetPaging);
                }
            };
            // ----------------

            // Modal de nou Perfil
            HeaderService.onChangeState($scope, function (message) {
                if(message.state.name == 'annexa.admin.task_templates') {
                    $rootScope.subHeadButtons = [
                        new HeadButtonSearch("#tableFilter"),
                        new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-task-template','fa fa-plus','global.commonAdmin.newTemplate',undefined,'openNewTaskTemplate')
                    ];
                }
            });
            HeaderService.changeState($state.current);

        $scope.doSearchFilter=function(queued) {
            $scope.tableTaskTemplate.reloadInternalData(true);
        }

        // Edició plantilles
        // ---------------
        $scope.viewTaskTemplate = function (id) {
            RestService.findOne('TaskTemplate', id)
                .then(function (data) {
                    var languages = $rootScope.app.languagedef;
                    var modal = angular.copy(CommonAdminModals.taskTemplateEdit);
                    modal.data = JSOG.decode(data.data);
                    modal.languageColumn = $scope.descriptionLanguageColumn;
                    modal.alerts = [];
                    modal.printTaskTemplate = function(){
                        if(modal.data && modal.data[$scope.descriptionLanguageColumn]){
                            return modal.data[$scope.descriptionLanguageColumn];
                        }
                        return '';
                    }
                    modal.updateTaskTemplate = function(val, prop){
                        if(prop) {
                            if(prop == 'descriptionLanguage') {
                                if(languages){
                                    var cont = 1;
                                    var valid = true;
                                    angular.forEach(languages, function (value, key) {
                                        if (!modal.data[prop + cont]) {
                                            valid = false;
                                        }
                                        cont++;
                                    });
                                }
                                if(!valid){
                                    return $filter('translate')('global.validation.required');
                                }
                            }else {
                                if(val){
                                    modal.data[prop] = val;
                                }else{
                                    return $filter('translate')('global.validation.required');
                                }
                            }
                            AdminFactory.insertOrUpdateTaskTemplate(modal.data).then(function (data) {
                                modal.data = data;
                                $scope.tableTaskTemplate.reloadInternalData(false);
                            }).catch(function (error) {
                                var errorTranslate = { msg: ErrorFactory.getErrorMessage('taskTemplates', 'save', error.data) };
                                modal.alerts.push({ msg: $filter('translate')(errorTranslate.msg)});
                            });
                        };
                    };
                    AnnexaModalFactory.showModal('modalEditTaskTemplate', modal);
                 })
        };
        // ---------------

        // Desat plantilles
        // --------------
        $scope.completedNew = function($modal) {
            if($modal.annexaFormly.form.$valid) {
                var data = $modal.annexaFormly.model.modal_body;

                var model = {
                    descriptionLanguage1: 'NotTranslated',
                    descriptionLanguage2: 'NotTranslated',
                    descriptionLanguage3: 'NotTranslated'
                };
                if(data.descriptionLanguage1) {
                    model.descriptionLanguage1 = data.descriptionLanguage1;
                }
                if(data.descriptionLanguage2) {
                    model.descriptionLanguage2 = data.descriptionLanguage2;
                }

                if(data.descriptionLanguage3) {
                    model.descriptionLanguage3 = data.descriptionLanguage3;
                }

                if(data.days){
                    model.days = data.days;
                }
                AdminFactory.insertOrUpdateTaskTemplate(model)
                    .then(function (data) {
                        $scope.tableTaskTemplate.reloadInternalData(false);
                        $modal.close();
                }).catch(function(error){
                    var errorTranslate = { msg: ErrorFactory.getErrorMessage('taskTemplates', 'save', error.data) };
                    $modal.alerts.push({ msg: $filter('translate')(errorTranslate.msg)});
                });
            }
        };
        // --------------

        // MODAL Nou Perfil
        // -----------------
        $scope.openNewTaskTemplate = function (id, readonly) {
            var data = {
                row: true,
                colClass: ' col-sm-12',
                labelClass: 'label-strong'
            };
            var modal = angular.copy(CommonAdminModals.taskTemplateNew);
            modal.annexaFormly = new AnnexaFormly();
            modal.alerts = [];
            var fields = [];
            fields.push(modal.annexaFormly.createField('descriptionLanguage1','annexaInputLanguage','',new AnnexaFormlyFieldLanguageTemplateOptions('descriptionLanguage','descriptionLanguage','global.literals.description','horitzontal',true,true,true,$rootScope.app.languagedef),data));
            fields.push(modal.annexaFormly.createField('days','annexaInputRow','',new AnnexaFormlyFieldTemplateOptions('number','global.literals.days',true,false),data));
            modal.annexaFormly.addGroup('modal_body','modal-body p-lg',fields);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {days:30, descriptionLanguage1:'', descriptionLanguage2:'', descriptionLanguage3:''};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = {readOnly: false};
            modal.extra = this;
            AnnexaFormlyFactory.showModal("modalNewTaskTemplate", modal, $scope.completedNew, false);
        }
        // -----------------------------------

        $scope.deleteTaskTemplate = function (id){
            AdminFactory.deleteTaskTemplate(id)
                .then(function (data) {
                    var decodedData = JSOG.decode(data.data);
                    $scope.tableTaskTemplate.reloadInternalData(true);
            }).catch(function (error) {
                var errorTranslate = { msg: ErrorFactory.getErrorMessage('taskTemplates', 'delete', error.data) };
                var err = dialogs.error(
                    $filter('translate')('DIALOGS_ERROR_MSG'),
                    $filter('translate')(errorTranslate.msg),
                    {
                        animation: true,
                        backdrop: 'static',
                        keyboard: true,
                        size: ''
                    }
                );
            });
        }
    }]);
