/**
 * Created by osirvent on 19/09/2016.
 */
angular
    .module('annexaApp')
    .controller('TramTemplatesController',['$scope', '$filter', 'TableFilter', 'DTColumnBuilder', 'Language', 'HeaderService', '$rootScope', '$state', 'CommonAdminModals', 'AnnexaFormlyFactory', 'Upload', '$timeout', 'AdminFactory', 'RestService', 'HelperService', 'AnnexaModalFactory', 'CommonAdminService', 'CommonService', 'globalModals', 'GlobalDataFactory', function ($scope, $filter, TableFilter, DTColumnBuilder, Language, HeaderService, $rootScope, $state, CommonAdminModals, AnnexaFormlyFactory, Upload, $timeout, AdminFactory, RestService, HelperService, AnnexaModalFactory, CommonAdminService, CommonService, globalModals, GlobalDataFactory) {
        $rootScope.loading(false);
        $scope.languageColumn = Language.getActiveColumn();
        $scope.cancelEditIsPublic = false;

        $scope.tfilter = new TableFilter();
        $scope.tfilter.addElement(Language.getActiveColumn(), 'text', 0, 'global.literals.name');
        $scope.tfilter.addElement('procedure', 'select', 1, 'global.literals.procedure', 3, HelperService.addAllSelect(angular.copy(AdminFactory.procedures),Language.getActiveColumn(), $scope.app.language), true, Language.getActiveColumn(), false).setFilterCallAux(true);

        $scope.dtDefTramTemplates = new TableData('tram','TramTemplate','./api/common/byfiler','rt<"row"<"col-md-6"<"row"<"col-md-6"l><"col-md-6"i>>><"col-md-6"p>>','name',[[1, 'asc']]);

        var tramTemplateNameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name', Language.getActiveColumn());
        var tramTemplateIsPublicColumn = new BooleanColumn($filter, 'global.literals.isPublic', 'public');
        var tramTemplateProcedureColumn = new DatabaseTranslatedListColumn($filter, 'global.literals.procedures', Language.getActiveColumn(), 'procedures');
        var tramTemplateActionColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                                                         new ActionButton('global.literals.see','openTramTemplate([data])','fa-eye'),
                                                         [new ActionButton('global.literals.remove','deleteTramTemplate([data])')]);

        var getFilterCallTramTemplate = function(){
            var filterCallTramTemplate= $scope.tfilter.getFilterCallAux();
            filterCallTramTemplate.langColumn = Language.getActiveColumn();
            return filterCallTramTemplate;
        };


        $scope.tableTramTemplates =  {
            dtColumns:[
                DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                DTColumnBuilder.newColumn(tramTemplateNameColumn.getColumn()).withTitle(tramTemplateNameColumn.getTitle()),
                DTColumnBuilder.newColumn(tramTemplateIsPublicColumn.getColumn()).withOption('width','80px').withTitle(tramTemplateIsPublicColumn.getTitle()).renderWith(tramTemplateIsPublicColumn.getRender),
                DTColumnBuilder.newColumn(tramTemplateProcedureColumn.getColumn()).withTitle(tramTemplateProcedureColumn.getTitle()).notSortable().renderWith(tramTemplateProcedureColumn.getRender),
                DTColumnBuilder.newColumn('id').withOption('width', '130px').withOption('className', 'text-center').withTitle(tramTemplateActionColumn.getTitle()).notSortable().renderWith(tramTemplateActionColumn.getRender)
            ],
            filterCall: $scope.tfilter.getFilterCall(),
            filterCallAux: getFilterCallTramTemplate(),
            reLoadInternalData: function (resetPaging) {
                this.filterCall = $scope.tfilter.getFilterCall();
                this.filterCallAux = getFilterCallTramTemplate();
                this.table.reloadData(function callbackInstance(json){}, resetPaging);
            }
        }

        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.tram_templates') {
                $rootScope.subHeadButtons = [
                    new HeadButtonSearch("#tableFilter"),
                    //new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-tramTemplates','fa fa-arrow-down','global.commonAdmin.literals.xmlTagsDescriptor',undefined,'xmlTagsDescriptor'),
                    new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-tramTemplates','fa fa-arrow-down','global.commonAdmin.literals.jsonTagsDescriptor',undefined,'jsonTagsDescriptor'),
                    new HeadButton('btn primary breadDivButton mr-sm-pt',undefined,'#admin-tramTemplates','fa fa-plus','global.commonAdmin.newTemplate',undefined,'newTramTemplate')                    
                ];
            }
        });

        HeaderService.changeState($state.current)

        $scope.saveTramTemplate = function ($modal) {
            if($modal.annexaFormly.form.$valid) {
                var data = $modal.annexaFormly.model.modal_body;

                var model = {
                    language1: data.language1,
                    language2: 'NoTranslated',
                    language3: 'NoTranslated',
                    isPublic: data.isPublic_true || false,
                    procedures: [],
                    presetSignCircuits: [],
                    editCircuit: data.editCircuit_true || false,
                    allowThirds: data.allowThirds_false || false,
                    documentType:undefined,
                    file: undefined,
                    profiles: []
                };

                if(data.language2) {
                    model.language2 = data.language2;
                }

                if(data.language3) {
                    model.language3 = data.language3;
                }

                if(data.file) {
                    model.file = data.file;
                }

                if(data.procedures) {
                    model.procedures = data.procedures;
                }

                if(data.presetSignCircuits) {
                    model.presetSignCircuits = data.presetSignCircuits;
                }
                if(data.documentType){
                    model.documentType = data.documentType;
                }
                if(data.profiles){
                	model.profiles = data.profiles;
                }
                $scope.uploadTemplate(model, $modal);
            }

        };

        $scope.uploadTemplate = function(model, $modal) {
            AdminFactory.addTramTemplate(model)
                .then(function (data) {
                    $modal.close();
                    $scope.tableTramTemplates.reLoadInternalData(true);
                    $timeout(function () {
                        //model.file.result = response.data;
                    });
                }).catch(function (error) {
            });
        } ;

        $scope.newTramTemplate = function () {
            var modal = angular.copy(CommonAdminModals.tramTemplateNew);
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = { isPublic_true: true, allowThirds: false};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = { readOnly: false};
            modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.labelProp = Language.getActiveColumn();
            modal.annexaFormly.fields[0].fieldGroup[1].templateOptions.options = $linq(AdminFactory.documentTypes).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
            modal.annexaFormly.fields[0].fieldGroup[3].templateOptions.options = [ { label: $filter('translate')('global.literals.isPublic'), value: true }];
            modal.annexaFormly.fields[0].fieldGroup[4].templateOptions.labelProp = Language.getActiveColumn();
            modal.annexaFormly.fields[0].fieldGroup[4].templateOptions.options = AdminFactory.procedures;
            modal.annexaFormly.fields[0].fieldGroup[5].templateOptions.labelProp = Language.getActiveColumn();
            modal.annexaFormly.fields[0].fieldGroup[5].templateOptions.options = AdminFactory.presetSignCircuits;
            modal.annexaFormly.fields[0].fieldGroup[6].templateOptions.options = [ { label: $filter('translate')('global.literals.editCircuit'), value: true }];
            modal.annexaFormly.fields[0].fieldGroup[7].templateOptions.options = [ { label: $filter('translate')('global.commonAdmin.modal.tramTemplate.allowThirds'), value: false }];
            AnnexaFormlyFactory.showModal('modalTramTemplate', modal, $scope.saveTramTemplate, false);
        }

        $scope.xmlTagsDescriptor = function() {
            $rootScope.loading(true);

            AdminFactory.getTramFieldsMetadataXML()
                .then(function (linkElement) {
                    var clickEvent = new MouseEvent("click", {
                        "view": window,
                        "bubbles": true,
                        "cancelable": false
                    });

                    $rootScope.loading(false);
                    linkElement.dispatchEvent(clickEvent);
                }).catch(function (error) {
                    $rootScope.loading(false);
            });
        }

        $scope.jsonTagsDescriptor = function() {
            $rootScope.loading(true);

            AdminFactory.getTramFieldsMetadataXML(true)
                .then(function (linkElement) {
                    var clickEvent = new MouseEvent("click", {
                        "view": window,
                        "bubbles": true,
                        "cancelable": false
                    });

                    $rootScope.loading(false);
                    linkElement.dispatchEvent(clickEvent);
                }).catch(function (error) {
                $rootScope.loading(false);
            });
        }

        $scope.updateTramTemplate = function (val, prop) {
            $scope.cancelEditIsPublic = false;

            var model = angular.copy(this.data);
            if(prop == "procedures") {
                this.alerts = [];
                model[prop] = val;
                model['public'] = false;
                this.data['public'] = false;
            } else if(prop == "public") {
                if((model.procedures != null && !val) || val) {
                    this.alerts = [];
                    model[prop] = val;
                    if(val) {
                        this.data['procedures'] = null;
                        model['procedures'] = null;
                    }
                } else {
                    $scope.cancelEditIsPublic = true;
                    this.alerts.push( { msg: $filter('translate')('global.commonAdmin.literals.errorEditIsPublic') });
                    return "";
                }
            } else if(prop == 'language1' || prop == 'language2' || prop == 'language3') {
              model = val;
            } else if(prop == 'documentType'){
            	if(val && val.id){
            		model[prop] = {"id":val.id}
            	}else {
            		model[prop] = undefined;	
            	}
            } else{
                model[prop] = val;
            }

            if(model.documentType && !model.documentType.id){
            	model.documentType = undefined;
            }
            
            if(prop == 'profiles'){
            	model[prop] = [];
                _.forEach(val, function(item) {
                	model[prop].push( { profile: { id: item.id } });
                });
            } else if(model.profiles && model.profiles.length > 0){
            	var profiles = [];
            	_.forEach(model.profiles, function(item) {
            		profiles.push( { profile: { id: item.id } });
                });
            	model.profiles = profiles;
            }
            
            AdminFactory.updateTramTemplate(model)
                .then(function (data) {
                    $scope.tableTramTemplates.reLoadInternalData(false);
                }).catch(function (error) {

            });
        }

        $scope.replaceDocument = function () {
            if(this.annexaFormly.form.$valid) {
                $rootScope.loading(true);
                var self = this;
                var data = this.annexaFormly.model.modal_body;

                var send = {
                    idTemplate: this.template.id,
                    file: data.file
                };

                data.file.upload = Upload.upload({
                    url: './api/tram/updateTramTemplateDocument',
                    data: send
                });

                data.file.upload
                    .then(function (data) {
                        if(data.data) {
                            var updatedTemplate = JSOG.decode(data.data);
                            self.callerModal.data.fileName = updatedTemplate.fileName;
                            self.callerModal.data.fileTypeDoc = updatedTemplate.fileTypeDoc;
                            self.callerModal.data.idDocumentManager = updatedTemplate.idDocumentManager;
                            self.callerModal.data.sizeDoc = updatedTemplate.sizeDoc;
                            $rootScope.loading(false);
                            self.close();
                        }
                    }).catch(function (error) {
                        $rootScope.loading(false);
                });
            }
        }

        $scope.replaceDocumentModal = function (template, callerModal) {
            var modal = globalModals.uploadFile;
            modal.title = 'global.literals.replace';
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = { readOnly: false};
            modal.template = template;
            modal.callerModal = callerModal;
            modal.annexaFormly.fields[0].fieldGroup[0].data.accept = '\'application/vnd.openxmlformats-officedocument.wordprocessingml.document\'';
            AnnexaFormlyFactory.showModal('modalUploadFile', modal, $scope.replaceDocument, false);
        }

        $scope.editOnline = function (idDocumentManager) {
            CommonService.getEditOnlineUrl(idDocumentManager).then(function(eou) {
                if(eou != null && eou != undefined && eou != ''){
                    window.location.href=eou;
                }
            }).catch(function(){
            });
        }

        $scope.printIsPublic = function (model) {
            if($scope.cancelEditIsPublic) {
                return $filter('translate')('global.literals.templateIsPublic');
            } else {
                if (model) {
                    return $filter('translate')('global.literals.templateIsPublic');
                } else {
                    return $filter('translate')('global.literals.tempalteNoIsPublic');
                }
            }
        }

        $scope.printPresetSignCircuits = function (model) {
            var presetSignCircuits = "";

            angular.forEach(model, function (val, key) {
                if(presetSignCircuits != "") {
                    presetSignCircuits += ", ";
                }

                presetSignCircuits += val[$scope.languageColumn];
            })

            return presetSignCircuits;
        }

        $scope.printEditPresetSignCircuit = function (model) {
            if(model) {
                return $filter('translate')('global.literals.editCircuit');
            } else {
                return $filter('translate')('global.literals.editCircuitNo');
            }
        }

        $scope.printAllowThirds = function (model) {
            if(model) {
                return $filter('translate')('global.commonAdmin.modal.tramTemplate.allowThirds');
            } else {
                return $filter('translate')('global.commonAdmin.modal.tramTemplate.noAllowThirds');
            }
        }

        $scope.openTramTemplate = function(id){
            if(id){
                RestService.findOne('TramTemplate', id)
                    .then(function(data) {
                        var modal = angular.copy(CommonAdminModals.tramTemplateEdit);
                        modal.data = JSOG.decode(data.data);
                        if(!modal.data.documentType){
                        	modal.data.documentType = {id:""};
                        }
                        modal.languageColumn = Language.getActiveColumn();
                        modal.updateTramTemplate = $scope.updateTramTemplate;
                        modal.replaceDocumentModal = $scope.replaceDocumentModal;
                        modal.editOnline = $scope.editOnline;
                        modal.printIsPublic = $scope.printIsPublic;
                        modal.procedures = AdminFactory.procedures;
                        modal.presetSignCircuits = AdminFactory.presetSignCircuits;
                        modal.printPresetSignCircuits = $scope.printPresetSignCircuits;
                        modal.printEditPresetSignCircuit = $scope.printEditPresetSignCircuit;
                        modal.printAllowThirds = $scope.printAllowThirds;
                        var documentTypes = $linq(AdminFactory.documentTypes).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
                        documentTypes.unshift( {id:"", language1:$filter('translate')('global.literals.empty'), language2:$filter('translate')('global.literals.empty'), language2:$filter('translate')('global.literals.empty')} );
                        modal.documentTypes = documentTypes;
                        
                        modal.data.profiles = $linq(modal.data.profiles).select("x => x.profile").toArray();
                        modal.printProfiles = function (model) {
                            var perfiles = '';
                            angular.forEach(model, function (val, key) {
                                if(perfiles != '') {
                                    perfiles += ', ';
                                }
                                perfiles += val[$scope.languageColumn];
                            });

                            return perfiles;
                        }
                        modal.profiles = GlobalDataFactory.allProfiles;
                        
                        AnnexaModalFactory.showModal('modalTramTemplateEdit', modal);
                    });
            }
        }
        $scope.doSearchFilter=function(queued) {
            $scope.tableTramTemplates.reLoadInternalData(true);
        }

        $scope.deleteTramTemplate = function (id) {
            CommonAdminService.deleteTramTemplate(id)
                .then(function(data) {
                    $scope.tableTramTemplates.reLoadInternalData(true);
                }).catch(function (error) {
            });
        }
    }]);