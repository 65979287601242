angular
    .module('annexaApp')
    .controller('RegisterEntryClassificationController',['$scope', 'RegFactory', 'Language', 'GlobalDataFactory', '$filter', 'AnnexaFormlyFactory', 'RestService', '$rootScope', function($scope, RegFactory, Language, GlobalDataFactory, $filter, AnnexaFormlyFactory, RestService, $rootScope) {
        $scope.filter = [
            { id: Language.getActiveColumn(), type: 'text', order: 1, label: 'global.literals.name' },
            { id: 'entryType', type: 'select', order: 2, label: 'global.literals.type', dataType: 'LOCAL', data: RegFactory.regEntryTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true },
            { id: 'classificationCode', type: 'text', order: 3, label: 'global.literals.code' },
            { id: 'procedure', type: 'select', order: 4, label: 'global.literals.procedures', dataType: 'LOCAL', data: GlobalDataFactory.procedures, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true }
        ];

        $scope.additionalColumns = [
            { id: 'entryType', width: '100px', column: new AngularTranslateColumn($filter, 'global.literals.type', 'global.literals.', true) },
            { id: 'classificationCode', width: '100px', title: $filter('translate')('global.literals.code') },
            { id: 'procedures', column: new ListTranslatedColumn($filter, 'global.literals.procedures', Language.getActiveColumn()) }
        ];

        var classificationModal = {
            title: 'global.literals.registerclassificationnew',
            size: '',
            icon: '',
            fieldsDefinition: [
                { type: 'field', id: 'entryType', fieldType: 'select', data: RegFactory.regEntryTypes, required: true, label: 'global.literals.type', isIdentifies: true,
                    printFunction:  function(entryType) {
                        if(entryType == 'INPUT' || (entryType && entryType.id == 'INPUT')) {
                            return $filter('translate')('global.literals.input');
                        } else if (entryType == 'OUTPUT' || (entryType && entryType.id == 'OUTPUT')) {
                            return $filter('translate')('global.literals.output');
                        }

                        return '';
                    }
                },
                { type: 'field', id: 'language1', fieldType: 'language', required: true, label: 'global.literals.name' },
                { type: 'field', id: 'classificationCode', fieldType: 'text', label: 'global.literals.code' },
                { type: 'field', id: 'procedures', fieldType: 'select_multiple', data: GlobalDataFactory.procedures, label: 'global.literals.procedures',
                    printFunction: function (procedures) {
                        var ret = '';

                        _.forEach(procedures, function(item) {
                            ret += ret != '' ? ', ' : '';
                            ret += item[Language.getActiveColumn()];
                        });

                        return ret;
                    }
                },
                { type: 'field', id: 'defaultExtract', fieldType: 'textarea', required: false, label: 'global.literals.extract', maxLength: 1024 },
            ],
            alerts: [],
            submitModal: function () {
            }
        };

        $scope.newElement = function () {
            var newModal = angular.copy(classificationModal);

            var submitNewRegisterEntryClassificationModal = function() {
                this.form.$setSubmitted();

                if(this.form.$valid) {
                    var model = {
                        language1: this.model.language1 || 'NotTranslated',
                        language2: this.model.language2 || 'NotTranslated',
                        language3: this.model.language3 || 'NotTranslated',
                        entryType: this.model.entryType,
                        classificationCode: this.model.classificationCode || undefined,
                        procedures: $linq(this.model.procedures).select(function (x) {
                            return { id: x};
                        }).toArray(),
                        defaultExtract: this.model.defaultExtract || undefined
                    };

                    RestService.insert('./api/reg/register_entry_classification', model)
                        .then(function () {
                            $rootScope.$broadcast('annexaAdminBaseReloadTable');
                            newModal.close();
                        }).catch(function(error) {
                            console.error(error);
                            newModal.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') });
                    });
                }
            };

            AnnexaFormlyFactory.showAnnexaFormModal('newRegisterEntryClassification', newModal, submitNewRegisterEntryClassificationModal);
        };

        $scope.viewElement = function(id) {
            var viewModal = angular.copy(classificationModal);

            viewModal.isEditable = true;
            viewModal.hideSubmit = true;
            viewModal.closeLabel = 'global.literals.close';
            viewModal.object = $scope.tableObjects[id];
            viewModal.submitModal = function (val, prop) {
                var newModel = angular.copy(this.model);

                switch (prop) {
                    case 'language1':
                    case 'language2':
                    case 'language3':
                        newModel = val;
                        break;
                    default:
                        newModel[prop] = val;
                        break;
                }

                if (newModel.entryType.id) {
                	newModel.entryType = newModel.entryType.id;
                }
                
                RestService.update('./api/reg/register_entry_classification/' + newModel.id, newModel)
                    .then(function () {
                        $rootScope.$broadcast('annexaAdminBaseReloadTable');
                    }).catch(function(error) {
                        console.error(error);
                });
            };

            AnnexaFormlyFactory.showAnnexaFormModal('newRegisterEntryClassification', viewModal);
        };
    }]);