angular
    .module('annexaApp')
    .controller('NotificationTypesController',['$scope', '$rootScope', 'CommonAdminModals', 'AnnexaFormlyFactory', 'RestService', 'AnnexaModalFactory', 'Language', '$filter', 'GlobalDataFactory', function($scope, $rootScope, CommonAdminModals, AnnexaFormlyFactory, RestService, AnnexaModalFactory, Language, $filter, GlobalDataFactory) {

        $scope.newElement = function() {
            var modal = angular.copy(CommonAdminModals.notificationTypeNew);
            modal.annexaFormly.model = { row1: {}, row2: { allowManualStatusChange: true }, row3: {}, row4: {} };
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal('modalNewNotificationType', modal, $scope.saveNotificationType, false);
        }

        $scope.statuses = [];

        $rootScope.$on('AnnexaNotificationStatusAdded', function(event, args) {
            $scope.statuses = [];

            _.forEach(args.statuses, function (value) {
                $scope.statuses.push(value);
            });
        });

        $rootScope.$on('AnnexaNotificationStatusUpdated', function(event, args) {
            if(args.status) {
                var update = {
                    createdDate: args.status.createdDate,
                    deleted: args.status.deleted,
                    id: args.status.id,
                    revision: args.status.revision,
                    type: args.status.type,
                    language1: args.status.language1 ? args.status.language1 : 'NotTranslated',
                    language2: args.status.language2 ? args.status.language2 : 'NotTranslated',
                    language3: args.status.language3 ? args.status.language3 : 'NotTranslated',
                    allowEvidence: args.status.allowEvidence,
                    globalStatus: args.status.globalStatus
                };

                if(update.id) {
                    RestService.update('./api/reg/notification_status/' + update.id, update)
                        .then(function () {
                            //Empty
                        }).catch(function () {
                        //Empty
                    });
                } else {
                    RestService.insert('./api/reg/notification_status', update)
                        .then(function() {
                            //Empty
                        }).catch(function() {
                        //Empty
                    });
                }
            }
        });

        $scope.saveNotificationType = function (notificationType) {
            var modal = this;

            var model = {
                language1: notificationType.annexaFormly.model.row1.language1,
                language2: notificationType.annexaFormly.model.row1.language2 ? notificationType.annexaFormly.model.row1.language2 : 'NotTranslated',
                language3: notificationType.annexaFormly.model.row1.language3 ? notificationType.annexaFormly.model.row1.language3 : 'NotTranslated',
                addressType: notificationType.annexaFormly.model.row2.addressType ? notificationType.annexaFormly.model.row2.addressType : null,
                allowManualStatusChange: notificationType.annexaFormly.model.row2.allowManualStatusChange,
                registerEntryChannel: {id:notificationType.annexaFormly.model.row3.registerEntryChannel},
                limitSizeDocumentsNotifications: notificationType.annexaFormly.model.row4.limitSizeDocumentsNotifications ? notificationType.annexaFormly.model.row4.limitSizeDocumentsNotifications : null,
                statuses: $scope.statuses
            }

            RestService.insert('./api/reg/notification_type', model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable'); 
                    $scope.statuses = [];
                    modal.close();
                }).catch(function () {
                    modal.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') })
            })
        }

        $scope.viewElement = function (id) {
            RestService.findOne('NotificationType', id)
                .then(function(data) {
                    var decodeddata = JSOG.decode(data.data);

                    var modal = CommonAdminModals.notificationTypeEdit;
                    modal.alerts = [];
                    modal.data = decodeddata;
                    modal.languageColumn = Language.getActiveColumn();
                    modal.nameColumn = 'name';
                    modal.addressTypes = [
                        { id: 'TELEMATIC_EMAIL', name: 'global.literals.TELEMATIC_EMAIL' },
                        { id: 'POSTAL', name: 'global.literals.POSTAL' },
                        { id: 'TELEMATIC_MOBILEPHONE', name: 'global.literals.TELEMATIC_MOBILEPHONE' }
                    ]
                    modal.registerEntryChannels =  GlobalDataFactory.registerEntryChannelsOutput;
                    modal.printAddressType = function (value) {
                        return $filter('translate')('global.literals.' + value);
                    }
                    modal.printRegisterEntryChannel = function(value){
                    	return value[Language.getActiveColumn()];
                    }
                    modal.amscs = [
                        { id: true, name: $filter('translate')('global.literals.yes') },
                        { id: false, name: $filter('translate')('global.literals.no') }
                    ];
                    modal.printAmcs = function(modelValue) {
                        if(modelValue) {
                            return $filter('translate')('global.literals.yes');
                        } else {
                            return $filter('translate')('global.literals.no');
                        }
                    };

                    modal.updateNotificationType = $scope.updateNotificationType;

                    AnnexaModalFactory.showModal('modalNotificationTypeEdit', modal);
                });
        }

        $scope.updateNotificationType = function(val, prop) {
            var model = this.data;

            if(prop == 'addressType') {
                model.addressType = val.id;
            } else if (prop == 'allowManualStatusChange') {
                model.allowManualStatusChange = val.id;
            }else if (prop == 'registerEntryChannel'){
            	model.registerEntryChannel = {id:val.id};
            }else if (prop == 'limitSizeDocumentsNotifications'){
            	model.limitSizeDocumentsNotifications = val;
            }
            else {
                model = val;
            }


            RestService.update('./api/reg/notification_type/' + model.id, model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                }).catch(function () {
                    //Empty
            });
        }
    }])