angular
    .module('annexaApp')
    .controller('RegisterChannelsController',['$scope', '$rootScope', 'CommonAdminModals', 'Language', '$filter', 'RestService', 'AnnexaModalFactory', 'RegFactory', function ($scope, $rootScope, CommonAdminModals, Language, $filter, RestService, AnnexaModalFactory, RegFactory) {
        $scope.newRegisterChannel = CommonAdminModals.registerChannelNew;

        $scope.saveRegisterChannel = function(registerChannel) {
            var modal = this;

            var model = {
                language1: registerChannel.annexaFormly.model.modal_body.language1,
                language2: registerChannel.annexaFormly.model.modal_body.language2,
                language3: registerChannel.annexaFormly.model.modal_body.language3,
                entryType: registerChannel.annexaFormly.model.modal_body.entryType,
                code: registerChannel.annexaFormly.model.modal_body.code,
                active: registerChannel.annexaFormly.model.modal_body.active
            }

            RestService.insert('./api/reg/register_entry_channel', model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                    modal.close();
                }).catch(function(error) {
                	if(error.data.exception.includes('DataIntegrityViolationException')) {
                		modal.alerts.push({ msg: $filter('translate')('global.literals.existRegisterChannelCode') });
                	} else {
                		modal.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') });
                	}
            })
        }

        var siNo = [
            { id: 'No', name: $filter('translate')('global.literals.no') },
            { id: 'Si', name: $filter('translate')('global.literals.yes') }
        ];
        
        $scope.filter = [
            { id: Language.getActiveColumn(), type: 'text', order: 1, label: 'global.literals.name' },
            { id: 'entryType', type: 'select', order: 2, label: 'global.literals.entryType', dataType: 'LOCAL', data: RegFactory.regEntryTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true },
            { id: 'code', type: 'text', order: 3, label: 'global.literals.codeType' },
            { id: 'active', type: 'select', order: 4, label: 'global.literals.active', dataType: 'LOCAL', data: siNo, addAll: true, nameProperty: 'name', callAux: true }
        ]

        $scope.additionalColumns = [
            { id: 'entryType', width: '100px', column: new AngularTranslateColumn($filter, 'global.literals.entryType', 'global.literals.', true) },
            { id: 'code', title: $filter('translate')('global.literals.codeType'), sortable: false },
            { id: 'active', width: '10%', column: new BooleanColumn($filter, 'global.literals.active')}
        ]

        $scope.viewElement = function (id) {
            RestService.findOne('RegisterEntryChannel', id)
                .then(function(data) {
                    var modal = angular.copy(CommonAdminModals.registerChannelEdit);
                    modal.alerts = [];
                    modal.data = JSOG.decode(data.data);
                    modal.languageColumn = Language.getActiveColumn();
                    modal.otherLangColumn = 'name';
                    modal.yesno = [
                        { id: true, name: $filter('translate')('global.literals.yes') },
                        { id: false, name: $filter('translate')('global.literals.no') }
                    ];
                    modal.updateRegisterChannel = $scope.updateRegisterChannel;
                    modal.printYesNo = function(modelValue) {
                        if(modelValue) {
                            return $filter('translate')('global.literals.yes');
                        } else {
                            return $filter('translate')('global.literals.no');
                        }
                    };
                    modal.entryTypesOptions = [
                        { id: 'INPUT', name: $filter('translate')('global.literals.INPUT') },
                        { id: 'OUTPUT', name: $filter('translate')('global.literals.OUTPUT') }
                    ]
                    modal.printEntryTypes = function(modelValue) {
                        return $filter('translate')('global.literals.' + modelValue);
                    };

                    AnnexaModalFactory.showModal('modalEditRegisterChannel', modal);
                });
        }

        $scope.updateRegisterChannel = function(val, prop) {
            var modal = this;
        	var model = this.data;
        	var entryTypeBck = this.data.entryType;
        	var codeBck = this.data.code;

            if(_.contains(['active', 'entryType'], prop)) {
                model[prop] = val.id;
            } else if(_.contains(['code'], prop)) {
            	model[prop] = val;
            }          
            
            RestService.checkDelete('./api/reg/register_entry_channel/check_delete/' + this.data.id)
            	.then(function(data) {
            		if(!_.contains(['entryType'], prop)) {
            			data = true;
            		}
            		
	                if(data) {
			            RestService.update('./api/reg/register_entry_channel/' + model.id, model)
			                .then(function() {
			                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
			                }).catch(function (error) {
			                    if(error.data.exception.includes('DataIntegrityViolationException')) {
			                    	if(_.contains(['code'], prop)) {
			                        	model[prop] = codeBck;
			                        }      
			                    	modal.alerts.push({ msg: $filter('translate')('global.literals.existRegisterChannelCode') });
			                	}
			            });
	                } else {
	                	if(_.contains(['entryType'], prop)) {
	                		model[prop] = entryTypeBck;
	            		}
	                	modal.alerts.push({ msg: $filter('translate')('global.literals.existRegisterChannels') });
	                }
            }).catch(function(error) {
            	
            });
        }
    }]);