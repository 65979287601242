angular
    .module('annexaApp')
    .controller('DiligenceTypesController',['$scope', '$rootScope', 'CommonAdminModals', 'Language', '$filter', 'RestService', 'AnnexaModalFactory', function ($scope, $rootScope, CommonAdminModals, Language, $filter, RestService, AnnexaModalFactory) {
        $scope.newDiligenceType = CommonAdminModals.diligenceTypeNew;

        $scope.saveDiligenceType = function(diligenceType) {
            var modal = this;

            var model = {
                language1: diligenceType.annexaFormly.model.modal_body.language1,
                language2: diligenceType.annexaFormly.model.modal_body.language2,
                language3: diligenceType.annexaFormly.model.modal_body.language3,
                type: (diligenceType.annexaFormly.model.modal_body.canStartDossier || diligenceType.annexaFormly.model.modal_body.canAppendDossier) ? 'ACCEPTANCE' : 'ACCESS',
                canStartDossier: diligenceType.annexaFormly.model.modal_body.canStartDossier,
                canAppendDossier: diligenceType.annexaFormly.model.modal_body.canAppendDossier
            }

            RestService.insert('./api/reg/register_diligence_type', model)
                .then(function () {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                    modal.close();
                }).catch(function(error) {
                    modal.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') });
            })
        }

        $scope.filter = [
            { id: Language.getActiveColumn(), type: 'text', order: 0, label: 'global.literals.name' }
        ]

        $scope.additionalColumns = [
            { id: 'canStartDossier', width: '10%', column: new BooleanColumn($filter, 'global.literals.canStartDossier')},
            { id: 'canAppendDossier', width: '10%', column: new BooleanColumn($filter, 'global.literals.canAppendDossier')}
        ]

        $scope.viewElement = function (id) {
            RestService.findOne('RegisterDiligenceType', id)
                .then(function(data) {
                    var modal = angular.copy(CommonAdminModals.diligenceTypeEdit);
                    modal.alerts = [];
                    modal.data = JSOG.decode(data.data);
                    modal.languageColumn = Language.getActiveColumn();
                    modal.otherLangColumn = 'name';
                    modal.yesno = [
                        { id: true, name: $filter('translate')('global.literals.yes') },
                        { id: false, name: $filter('translate')('global.literals.no') }
                    ];
                    modal.updateDiligenceType = $scope.updateDiligenceType;
                    modal.printYesNo = function(modelValue) {
                        if(modelValue) {
                            return $filter('translate')('global.literals.yes');
                        } else {
                            return $filter('translate')('global.literals.no');
                        }
                    }

                    AnnexaModalFactory.showModal('modalEditDiligenceType', modal);
                });
        }

        $scope.updateDiligenceType = function(val, prop) {
            var model = this.data;

            if(_.contains(['canStartDossier', 'canAppendDossier'], prop)) {
                model[prop] = val.id;
            } else {
                model = val;
            }

            RestService.update('./api/reg/register_diligence_type/' + model.id, model)
                .then(function() {
                    $rootScope.$broadcast('annexaAdminBaseReloadTable');
                }).catch(function () {
                    //Empty
            });
        }
    }]);