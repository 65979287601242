/**
 * Created by dpardell on 23/11/2015.
 */

angular
    .module('annexaApp')
    .controller('ProfileAdminController', ['$scope', '$http', '$state', '$compile', '$filter', 'DTColumnBuilder','CommonAdminService', 'apiAdmin', 'AnnexaFormlyFactory', 'CommonAdminModals', '$rootScope', 'HeaderService', 'AdminFactory', 'RestService', 'AnnexaModalFactory', 'TableFilter','Language', 'dialogs',
        function ($scope, $http, $state, $compile, $filter, DTColumnBuilder, CommonAdminService, apiAdmin, AnnexaFormlyFactory, CommonAdminModals, $rootScope, HeaderService, AdminFactory, RestService, AnnexaModalFactory, TableFilter, Language, dialogs) {

            // Funcions control mostrar botó Activar/Desactivar
            // -------------------------------------------------
            var showEnableAction = function (data, type, full, meta) {
                if(full.expiryDate == null || new Date() < new Date(full.expiryDate)){
                    return false;
                } else {
                    return true;
                }
            }

            var showDisableAction = function (data, type, full, meta) {
                if(full.expiryDate == null || new Date() < new Date(full.expiryDate)){
                    return true;
                } else {
                    return false;
                }
            }
            // -------------------------------------------------

            // TAULA PRINCIPAL
            // ----------------
            $scope.tfilter = new TableFilter();
            $scope.tfilter.addElement(Language.getActiveColumn(), 'text', 0, 'global.literals.name');
            
            //$scope.dtDefProfiles = new TableData('common','Profile','./api/common/byfilter','rt<"row"<"col-md-6"<"row"<"col-md-6"l><"col-md-6"i>>><"col-md-6"p>>','id',[[0, 'asc']], undefined, undefined,undefined, undefined, undefined, 2);
            $scope.dtDefProfiles = new TableData('common', 'Profile', './api/common/byfiler').setSort([[2, 'asc']]).setSortName('name');

            var ProfilesNameColumn = new DatabaseTranslatedColumn($filter, 'global.literals.name', Language.getActiveColumn());
            var ProfilesActionColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                new ActionButton('global.literals.see','viewProfile([data])','fa-eye'),[
                    //new ActionButton('global.commonAdmin.datatable.options.delete','deleteProfile([data])'),
                    new ActionButton('global.commonAdmin.datatable.options.disable','disableProfile([data])', undefined, undefined, showDisableAction),
                    new ActionButton('global.commonAdmin.datatable.options.enable','enableProfile([data])', undefined, undefined, showEnableAction)
                ]);

            $scope.createDateColumn = new DateColumn($filter,'global.commonAdmin.datatable.createDate');
            $scope.expiryDateColumn = new DateColumn($filter,'global.commonAdmin.datatable.expiryDate');

            $scope.tableProfiles = {
                dtColumns:[
                    DTColumnBuilder.newColumn('id').withTitle("id").withOption('width', '6%'),
                    DTColumnBuilder.newColumn('acronym').withOption('width', '120px').withTitle($filter('translate')('global.literals.acronym')),
                    DTColumnBuilder.newColumn(ProfilesNameColumn.getColumn()).withTitle(ProfilesNameColumn.getTitle()),
                    DTColumnBuilder.newColumn('profileCode').withOption('width', '120px').withTitle($filter('translate')('global.literals.code')),
                    //DTColumnBuilder.newColumn('createdDate').withOption('width', '10%').withTitle($scope.createDateColumn.getTitle()).renderWith($scope.createDateColumn.getRender),
                    //DTColumnBuilder.newColumn('expiryDate').withOption('width', '10%').withTitle($scope.expiryDateColumn.getTitle()).renderWith($scope.expiryDateColumn.getRender),
                    DTColumnBuilder.newColumn('id').withOption('width', '120px').withOption('className', 'text-center').withTitle(ProfilesActionColumn.getTitle()).notSortable().renderWith(ProfilesActionColumn.getRender)
                ],
                filterCall: $scope.tfilter.getFilterCall(),
                filterCallAux : { langColumn: Language.getActiveColumn() },
                reloadInternalData: function (resetPaging) {
                    this.filterCall = $scope.tfilter.getFilterCall();
                    this.filterCallAux = { langColumn: Language.getActiveColumn() };
                    this.table.reloadData(function callbackInstance(json){}, resetPaging);
                }
            };
            // ----------------

            // Modal de nou Perfil
            HeaderService.onChangeState($scope, function (message) {
                if(message.state.name == 'annexa.admin.profiles') {
                    $rootScope.subHeadButtons = [
                        new HeadButtonSearch("#tableFilter"),
                        new HeadButton('btn primary breadDivButton mr-sm-pt mb-sm-pt mb-md-0',undefined,'#admin-profiles','fa fa-plus','global.commonAdmin.modal.profile.titleNew',undefined,'openNewProfile'),
                        new HeadButton('btn primary breadDivButton mr-sm-pt mb-sm-pt mb-md-0',undefined,'#admin-profiles','fa fa-download','global.literals.export',undefined,'exportProfiles')
                    ];
                }
            });
            HeaderService.changeState($state.current);

        $scope.doSearchFilter=function(queued) {
            $scope.tableProfiles.reloadInternalData(true);
        }

        // Edició Perfils
        // ---------------
        $scope.viewProfile = function (id) {
            RestService.findOne('Profile', id)
                .then(function (data) {
                    var modal = CommonAdminModals.profileEdit;
                    modal.alerts = [];
                    modal.data = JSOG.decode(data.data);
                    AdminFactory.profileEdit = angular.copy(modal.data);
                    delete AdminFactory.profileEdit.content;
                    modal.languageColumn = Language.getActiveColumn();
                    modal.updateProfile = $scope.updateProfile;

                    AnnexaModalFactory.showModal("modalProfileEdit", modal);
                 })
        };
        // ---------------

        // Actualització Perfils
        // ----------------------
        $scope.updateProfile = function (val, prop) {
            switch (prop) {
                case 'language1':
                case 'language2':
                case 'language3':
                    AdminFactory.profileEdit.language1 = val.language1;
                    AdminFactory.profileEdit.language2 = val.language2;
                    AdminFactory.profileEdit.language3 = val.language3;
                    break;
                case 'acronym':
                    AdminFactory.profileEdit.acronym = val;
                case 'profileCode':
                    AdminFactory.profileEdit.profileCode = val;
                    break;
            }

            var self = this;

            CommonAdminService.insertOrUpdateProfile(AdminFactory.profileEdit)
                .then(function (data) {
                    var decodedData = JSOG.decode(data);
                    AdminFactory.profileEdit = decodedData;
                    $scope.tableProfiles.reloadInternalData(false);
                    self.alerts = [];
                }).catch(function (error) {
                	self.alerts = [];
                	if (error.message == 'DataIntegrityViolationException.acronym') {
                		self.alerts.push({msg: $filter('translate')('global.errors.existAcronym')});
                	} else if(error.message == 'DataIntegrityViolationException.profile_code') {                		
                		self.alerts.push({msg: $filter('translate')('global.errors.existCode')});
                	} else {                		
                		self.alerts.push({msg: $filter('translate')('global.errors.unknownUpdate')});
                	}
                });
        };
        // ----------------------

        // Desat Perfils
        // --------------
            $scope.saveProfile = function($modal) {
                if($modal.annexaFormly.form.$valid) {
                    var data = $modal.annexaFormly.model.modal_body;

                    var model = {
                        language1: data.language1,
                        language2: 'NotTranslated',
                        language3: 'NotTranslated',
                    };

                    if(data.language2) {
                        model.language2 = data.language2;
                    }

                    if(data.language3) {
                        model.language3 = data.language3;
                    }

                    if(data.acronym) {
                        model.acronym = data.acronym;
                    }

                    if(data.profileCode) {
                        model.profileCode = data.profileCode;
                    }

                    CommonAdminService.insertOrUpdateProfile(model)
                        .then(function (data) {
                            $scope.tableProfiles.reloadInternalData(false);
                            $modal.close();
                        }).catch(function (error) {
                        	$modal.alerts = [];
                        	if (error.message == 'DataIntegrityViolationException.acronym') {
                        		$modal.alerts.push({msg: $filter('translate')('global.errors.existAcronym')});
                        	} else if(error.message == 'DataIntegrityViolationException.profile_code') {
                        		$modal.alerts.push({msg: $filter('translate')('global.errors.existCode')});
                        	} else {
                        		$modal.alerts.push({msg: $filter('translate')('global.errors.unknownNew')});
                        	}
                        }); 
                }
            };
        // --------------

        // MODAL Nou Perfil
        // -----------------
        $scope.openNewProfile = function (id, readonly) {
            var modal = CommonAdminModals.profileNew;
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = { readOnly: readonly};
            AnnexaFormlyFactory.showModal("modalProfiles", modal, $scope.saveProfile, false);
        }
        // ------------------
            
        // Activació / desactivació perfils
        // -----------------------------------
        $scope.disableProfile = function (id){
            var content = {
                id:id,
                expiryDate:new Date()
            };
            CommonAdminService.updateObject(apiAdmin.getProfiles,id,content).then(function (dataUpdate) {
                $scope.tableProfiles.reloadInternalData(true);
            }).catch(function () {
            });
        }

        $scope.enableProfile = function (id){
            var content = {
                id:id,
                expiryDate:null
            };
            CommonAdminService.updateObject(apiAdmin.getProfiles,id,content).then(function (dataUpdate) {
                $scope.tableProfiles.reloadInternalData(true);
            }).catch(function () {
            });
        }
        // -----------------------------------

        $scope.deleteProfile = function (id){
            CommonAdminService.deleteProfile(id)
                .then(function (data) {
                    var decodedData = JSOG.decode(data.data);
                    $scope.tableProfiles.reloadInternalData(false);
                }).catch(function (error) {
            });
        }

        $scope.exportProfiles = function(){
            $rootScope.showLoadingdivSignin = true;
            RestService.findAll('Profile').then(function (data) {
                var listProfiles = JSOG.decode(data.data);
                var contentType = 'application/octet-stream';
                var fileName = "profileUsersExport.xls";
                var xlsFile = '<?xml version="1.0"?>\n';
                xlsFile += '<ss:Workbook xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">\n';
                xlsFile += '<ss:Worksheet ss:Name="'+$filter('translate')('global.literals.profiles')+'">\n';
                xlsFile += '<ss:Table>\n\n';
                if(listProfiles){
                    angular.forEach(listProfiles, function(value, key){
                        xlsFile += '<ss:Row>\n';
                        xlsFile += '  <ss:Cell>\n';
                        xlsFile += '    <ss:Data ss:Type="String">' + value.id + '</ss:Data>\n';
                        xlsFile += '  </ss:Cell>\n';
                        xlsFile += '  <ss:Cell>\n';
                        xlsFile += '    <ss:Data ss:Type="String">' + value[Language.getActiveColumn()] + '</ss:Data>\n';
                        xlsFile += '  </ss:Cell>\n';
                        xlsFile += '</ss:Row>\n';
                        angular.forEach(value.userProfiles,function(value2, key2){
                            if(value2.user && !value2.user.deleted) {
                                xlsFile += '<ss:Row>\n';
                                xlsFile += '  <ss:Cell>\n';
                                xlsFile += '    <ss:Data ss:Type="String"></ss:Data>\n';
                                xlsFile += '  </ss:Cell>\n';
                                xlsFile += '  <ss:Cell>\n';
                                xlsFile += '    <ss:Data ss:Type="String"></ss:Data>\n';
                                xlsFile += '  </ss:Cell>\n';
                                xlsFile += '  <ss:Cell>\n';
                                xlsFile += '    <ss:Data ss:Type="String">';
                                xlsFile += value2.user.name;
                                xlsFile += " " + value2.user.surename1;
                                if (value2.user.surename2) {
                                    xlsFile += " " + value2.user.surename2;
                                }
                                xlsFile += '    </ss:Data>\n';
                                xlsFile += '  </ss:Cell>\n';
                                xlsFile += '</ss:Row>\n';
                            }
                        })
                    })
                }
                xlsFile += '\n</ss:Table>\n';
                xlsFile += '</ss:Worksheet>\n';
                xlsFile += '</ss:Workbook>\n';
                var file = new Blob([xlsFile], {type: contentType});
                if(window.navigator.msSaveOrOpenBlob) {
                    try {
                        $rootScope.showLoadingdivSignin = false;
                        window.navigator.msSaveOrOpenBlob(file, fileName);
                    } catch(e) {
                        console.log(e.message);
                    }
                } else {
                    var linkElement = document.createElement('a');
                    try {
                        var url = window.URL.createObjectURL(file);
                        linkElement.setAttribute('href', url);
                        linkElement.setAttribute("download", fileName);
                        var clickEvent = new MouseEvent("click", {
                            "view": window,
                            "bubbles": true,
                            "cancelable": false
                        });
                        $rootScope.showLoadingdivSignin = false;
                        linkElement.dispatchEvent(clickEvent);
                    } catch (ex) {
                        console.log(ex);
                        $rootScope.showLoadingdivSignin = false;
                    }
                }
            }).catch(function(error){
                var err = dialogs.error(
                    $filter('translate')('DIALOGS_ERROR_MSG'),
                    $filter('translate')('global.errors.exportError'),
                    {
                        animation: true,
                        backdrop: 'static',
                        keyboard: true,
                        size: ''
                    }
                );
            });
        }


    }]);
