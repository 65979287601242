/**
 * Created by osirvent on 31/03/2017.
 */
angular
    .module('annexaApp')
    .controller('PositionsController',['$scope', 'HeaderService', '$state', 'CommonAdminModals', 'AdminFactory', 'Language', 'TableFilterFactory', '$filter', 'RestService', 'AnnexaModalFactory', 'dialogs', '$rootScope', 'AnnexaFormlyFactory', function($scope, HeaderService, $state, CommonAdminModals, AdminFactory, Language, TableFilterFactory, $filter, RestService, AnnexaModalFactory, dialogs, $rootScope, AnnexaFormlyFactory) {
        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.admin.positions') {
                $rootScope.subHeadButtons = [
                    new HeadButtonSearch("#tableFilter"),
                    new HeadButton('btn primary breadDivButton mr-sm-pt mb-md-0 mb-sm-pt ',undefined,'#positions','fa fa-plus','global.literals.newPosition',undefined,'newPosition')
                ];
            }
        });

        HeaderService.changeState($state.current);

        $scope.alerts = [];

        $scope.newPosition = function () {
            var modal = CommonAdminModals.positionNew;
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            AnnexaFormlyFactory.showModal("modalPositions", modal, $scope.savePosition, false);
        }

        $scope.savePosition = function(position) {
            var model = { language1: 'NotTranslated', language2: 'NotTranslated', language3: 'NotTranslated' };
            var isNew = false;
            var modal = undefined;

            if(position.hasOwnProperty('annexaFormly')) {
                modal = this;

                model = {
                    language1: position.annexaFormly.model.modal_body.language1,
                    language2: position.annexaFormly.model.modal_body.language2 ? position.annexaFormly.model.modal_body.language1 : 'NotTranslated',
                    language3: position.annexaFormly.model.modal_body.language3 ? position.annexaFormly.model.modal_body.language1 : 'NotTranslated',
                };

                isNew = true;
            } else {
                model = position;
            }

            AdminFactory.newPosition(model)
                .then(function(data) {
                    $scope.tableDefinition.reloadInternalData(false);
                    if(isNew && modal) {
                        modal.close();
                    }
                }).catch(function (error) {
                    if(isNew && modal) {
                        modal.alerts.push({msg: $filter('translate')('global.errors.unknownNew')})
                    }
            });
        }

        $scope.filter = [
            { id: Language.getActiveColumn(), type: 'text', order: 0, label: 'global.literals.name' }
        ];

        var getFilterCall = function() {
            return TableFilterFactory.getFilterCall($scope.filter);
        }

        var getFilterCallAux = function() {
            var filterCallAux = TableFilterFactory.getFilterCallAux($scope.filter, true);

            filterCallAux.langColumn = Language.getActiveColumn();

            return filterCallAux;
        }

        $scope.tableDefinition = {
            id: 'tablePositions',
            origin: 'common',
            objectType: 'Position',
            sortName: Language.getActiveColumn(),
            sort: [[1, 'asc']],
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: [
                { id: 'id', width: '6%', column: new DatabaseTranslatedColumn($filter, 'id', 'id') },
                { id: Language.getActiveColumn(), column: new DatabaseTranslatedColumn($filter, 'global.literals.name', Language.getActiveColumn()) },
                { id: 'id', width: '12%', column: new ActionsColumn($filter('translate')('global.literals.actions'), new ActionButton('global.literals.see', 'viewPosition([data])', 'fa-eye'), [ new ActionButton('global.literals.remove', 'deletePosition([data])') ])}
            ],
            containerScope: $scope
        }

        $scope.viewPosition = function(id) {
            RestService.findOne('Position', id)
                .then(function(data) {
                    var modal = CommonAdminModals.positionEdit;
                    modal.data = JSOG.decode(data.data);
                    modal.languageColumn = Language.getActiveColumn();
                    modal.updatePosition = $scope.updatePosition;

                    AnnexaModalFactory.showModal('modalPositionEdit', modal);
                })
        }

        $scope.updatePosition = function (val ,prop) {
            $scope.savePosition(val);
        }

        $scope.deletePosition = function(id) {
            AdminFactory.checkDelete(id)
                .then(function (data) {
                    if(data) {
                        var dlg = dialogs.confirm(
                            $filter('translate')('global.literals.deletePosition'),
                            $filter('translate')('global.literals.confirnDeletePosition'),
                            {
                                animation: true,
                                background: 'static',
                                keyboard: true,
                                size: ''
                            }
                        );

                        dlg.result.then(function (btn) {
                            AdminFactory.deletePosition(id)
                                .then(function(data) {
                                    $scope.tableDefinition.reloadInternalData(true);
                                }).catch(function (error) {
                                    $scope.alerts.push({ msg: 'global.errors.unknownDelete' });
                            });

                        }, function(btn) {});
                    } else {
                        var ok = dialogs.notify(
                            '',
                            $filter('translate')('global.errors.checkDeletePosition'),
                            {
                                animation: true,
                                backdrop: 'static',
                                keyboard: true,
                                size: ''
                            }
                        );
                    }
                }).catch(function(error) {
                    $scope.alerts.push({ msg: 'global.errors.unknownDelete' });
            });
        }
    }]);