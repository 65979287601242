/**
 * Created by osirvent on 22/07/2016.
 */
var procedure = function(procedure, server) {
    this.id=procedure.id;
    this.createdDate=procedure.createdDate;
    this.language1=procedure.language1;
    this.language2=procedure.language2;
    this.language3=procedure.language3;
    this.descriptionLanguage1=procedure.descriptionLanguage1;
    this.descriptionLanguage2=procedure.descriptionLanguage2;
    this.descriptionLanguage3=procedure.descriptionLanguage3;
    this.informationLanguage1=procedure.informationLanguage1;
    this.informationLanguage2=procedure.informationLanguage2;
    this.informationLanguage3=procedure.informationLanguage3;
    this.maxDays=procedure.maxDays;
    this.acronym=procedure.acronym;
    this.active=procedure.active;
    this.family=procedureObject(procedure.family, server);
    this.archiveClassification=procedureObject(procedure.archiveClassification, server);
    this.showSubject=procedure.showSubject;
    this.showClassification=procedure.showClassification;
    this.procedureViewProfiles=procedureListObject(procedure.procedureViewProfiles, server, 'profile');
    this.procedureStartProfiles=procedureListObject(procedure.procedureStartProfiles, server, 'profile');
    this.procedureResponsibleProfiles=procedureListObject(procedure.procedureResponsibleProfiles, server, 'profile');
    this.procedureClassifications=procedureListObject(procedure.procedureClassifications, server);
    this.allowUserChangeMaxDays = procedure.allowUserChangeMaxDays;
    this.transactionTypes = procedureListObject(procedure.transactionTypes, server, 'transactionType');
    this.tramitationType = procedureObject(procedure.tramitationType, server);
    //this.transactions=procedureListObject(procedure.transactions);
    //this.transactionTransitions=procedureListObject(procedure.transactionTransitions);
};


var procedureObject = function(object, server) {
    var obj = undefined;
    if(object) {
        if(server) {
            obj = {id: object};
        }else{
            obj = object.id;
        }
    }
    return obj;
}

var procedureListObject = function(objects, server, name){
    var objs = undefined;
    if(objects) {
        if(name) {
            objs = [];
            angular.forEach(objects, function (value, key) {
                if (server) {
                    var obj = {};
                    obj[name] = {id: value};
                    objs.push(obj);
                } else {
                    objs.push(value);
                }

            });
        }else{
            objs = objects;
        }
    }
    return objs;
}


